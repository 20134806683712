import React from 'react';
import "./index.css";


const Loader = () => {

  
return (
        <>
       
        
<div class="loader"></div>
      
       </>
    );
}


export default Loader;