import React, { useEffect } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import Auth from "../auth/auth";
import Approute from "./Approute";
import Authroute from "./Authroute";
import { useSelector } from 'react-redux';


const url = new URL(window.location.href);
const pathname = url.pathname;
const segments = pathname.split('/');
const desiredPath = '/' + segments.slice(2).join('/');

const NavigationHandler = () => {
  const history = useHistory();
  const myCountry = useSelector((state) => state.NewHostingReducer.region);

  
  useEffect(() => {
   
    if( localStorage.getItem("@appToken")){
    
    
    }else{
      if (myCountry === "NA") {
        history.push(`/us-en`)
        // history.push(`/us-en${desiredPath}`)

      }
      if(myCountry === "AE"){
        history.push(`/ae-en`)
        // history.push(`/ae-en${desiredPath}`)
      }
     
     if(myCountry === "KSA"){
      // history.push(`/sa-en${desiredPath}`)
      history.push(`/sa-en`)
     }
     
    }   
  }, [myCountry]);

  return null;
};

function Routes(props) {
  const auth = Auth.getAuth();

  return (
    <Router>
      <NavigationHandler />
      {auth ? (
        <div>
          <Approute />
        </div>
      ) : (
        <div  >
          <Authroute />
        </div>
      )}
    </Router>
  );
}

export default Routes

