import { Country } from "../Content/country";

export const getRegion = ()=>{
      const resolvedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const tzArr = resolvedTimeZone.split("/");
      const city = tzArr[tzArr.length - 1];
      const country = Country[city];
      
      return mapCountryToContinent(country)
}

const mapCountryToContinent = (countryCode) => {
    // Define your countryToContinent mapping here
    const countryToContinent = {
      // North America
      "United States": "NA",
      "Canada": "NA",
      "Mexico": "NA",
      "Belize": "NA",
      "Guatemala": "NA",
      "El Salvador": "NA",
      "Honduras": "NA",
      "Costa Rica": "NA",
      "Nicaragua": "NA",
      "Panama": "NA",
      "Bahamas": "NA",
      "Cuba": "NA",
      "Jamaica": "NA",
      "Haiti": "NA",
      "Dominican Republic": "NA",
      "Puerto Rico": "NA",
      "Dominica": "NA",
      "Saint Kitts and Nevis": "NA",
      "Antigua and Barbuda": "NA",
      "Saint Vincent and the Grenadines": "NA",
      "Saint Lucia": "NA",
      "Barbados": "NA",
      "Grenada": "NA",
      "Trinidad and Tobago": "NA",
      "Saudi Arabia": "KSA",
  "United Arab Emirates": "AE",
    };  
    return countryToContinent[countryCode] || "AE";
  };
