import React from "react";
import { Route, Switch } from "react-router-dom";
import {lazy} from "react"
const PreviewTemplatePage = lazy(() => import("../components/preview-template"));
const BlogInner = lazy(() => import("../components/BlogInner"));
const blog = lazy(() => import("../components/blog"));
const WebProcess = lazy(() => import("../components/web-process"));
const OurCustomer = lazy(() => import("../components/ourCustomer"));
const Redesign = lazy(() => import("../components/redesign"));
const Construction = lazy(() => import("../components/Construction"));
const RealState = lazy(() => import("../components/RealState"));
const Industries = lazy(() => import("../components/Industries"));
const Index = lazy(() => import("../components/404"));
const TermsConditionPage = lazy(() => import("../components/terms-and-conditions"));
const SignUp = lazy(() => import("../components/signup/index"));
const CallSchedulePage = lazy(() => import("../components/schedule-a-call"));
const ReviewsPage = lazy(() => import("../components/reviews"));
const PrivacyPolicyPage = lazy(() => import("../components/privacy-policy"));
const ReferPage = lazy(() => import("../components/refer"));
const PricingCardsPage = lazy(() => import("../components/pricing"));
const Forgot = lazy(() => import("../components/Forgotpassword/index"));
const Reset = lazy(() => import("../components/Forgotpassword/Reset"));
const HomePage = lazy(() => import("../components/home/middle"));
const FeaturesPage = lazy(() => import("../components/features"));
const Login = lazy(() => import("../components/login"));
const PortfolioPage = lazy(() => import( "../components/portfolio"));

export default function Authroute() {
  return (
    <Switch>
      <Route exact path="/us-en" component={HomePage} />
      <Route exact path="/ae-en" component={HomePage} />
      <Route exact path="/ae-en/web-development-company-in-dubai" component={HomePage} />
      <Route exact path="/ae-en/web-design-company-in-abu-dhabi/" component={HomePage} />
      <Route exact path="/sa-en" component={HomePage} />
      <Route exact path="/sa-en/website-development-company-in-riyadh/" component={HomePage} />      
      <Route path="/sign-up" component={SignUp} />
      <Route path="/login" component={Login} />
      <Route path="/user/reset/:token" component={Reset} />
      <Route path="/forget-password" component={Forgot} />
      <Route path="/us-en/features" component={FeaturesPage} />
      <Route path="/ae-en/features" component={FeaturesPage} />
      <Route path="/sa-en/features/" component={FeaturesPage} />

      <Route path="/us-en/pricing" component={PricingCardsPage} />
      <Route path="/ae-en/pricing" component={PricingCardsPage} />
      <Route path="/sa-en/pricing/" component={PricingCardsPage} />

      <Route path="/refer" component={ReferPage} />
      <Route path="/reviews" component={ReviewsPage} />

      <Route path="/privacy-policy" component={PrivacyPolicyPage} />      
      <Route path="/us-en/schedule-a-call" component={CallSchedulePage} />
      <Route path="/ae-en/schedule-a-call" component={CallSchedulePage} />
      <Route path="/sa-en/schedule-a-call" component={CallSchedulePage} />
      
      
      <Route path="/us-en/portfolio" component={PortfolioPage} />
      <Route path="/ae-en/portfolio" component={PortfolioPage} />
      <Route path="/sa-en/portfolio" component={PortfolioPage} />

      <Route path="/terms" component={TermsConditionPage} />
      
     <Route path="/us-en/logistics-management-websites" component={Industries} />
     <Route path="/ae-en/logistics-website-development/" component={Industries} />
     <Route path="/ae-en/web-development-for-logistics-companies-in-dubai" component={Industries} />
     <Route path="/ae-en/logistics-management-website-development-abu-dhabi/" component={Industries} />
     <Route path="/sa-en/web-development-services-for-logistics-company/" component={Industries} />
   
     <Route path="/sa-en/web-development-for-logistics-company-in-riyadh/" component={Industries} />


     <Route path="/us-en/real-estate-website-design" component={RealState} />
     <Route path="/ae-en/web-development-services-for-real-estate-company/" component={RealState} />
     <Route path="/ae-en/web-development-for-real-estate-company-in-dubai" component={RealState} />
     <Route path="/ae-en/abu-dhabi-real-estate-website-development/" component={RealState} />
     <Route path="/sa-en/web-development-for-saudi-real-estate-company/" component={RealState} />
     <Route path="/sa-en/web-development-for-real-estate-company-riyadh/" component={RealState} />


     <Route path="/us-en/construction-website-design" component={Construction} />
     <Route path="/ae-en/web-development-for-construction-company-in-uae/" component={Construction} />
     <Route path="/ae-en/web-development-for-construction-company-in-dubai" component={Construction} />
     <Route path="/ae-en/web-development-services-for-abu-dhabi-construction-company/" component={Construction} />
     <Route path="/sa-en/web-development-for-saudi-arabian-construction-company/" component={Construction} />
     <Route path="/sa-en/web-development-for-construction-companies-in-riyadh/" component={Construction} />


      <Route path="/us-en/website-redesign" component={Redesign} />
      <Route path="/ae-en/website-redesign" component={Redesign} />
      <Route path="/sa-en/website-redesign" component={Redesign} />

      <Route path="/us-en/reviews" component={OurCustomer} />
      <Route path="/ae-en/reviews" component={OurCustomer} />
      <Route path="/sa-en/reviews/" component={OurCustomer} />

      <Route path="/us-en/web-design-process" component={WebProcess} />
      <Route path="/ae-en/web-design-process" component={WebProcess} />
      <Route path="/sa-en/web-design-process" component={WebProcess} />

      <Route path="/us-en/blog" component={blog} />
      <Route path="/ae-en/blog" component={blog} />
      <Route path="/sa-en/blog" component={blog} />


      <Route exact path="/ae-en/preview" component={PreviewTemplatePage} />
      <Route exact path="/us-en/preview" component={PreviewTemplatePage} />
      <Route exact path="/sa-en/preview" component={PreviewTemplatePage} />

      <Route exact path="/ae-en/:ids" component={BlogInner} />
      <Route exact path="/us-en/:ids" component={BlogInner} />
      <Route exact path="/sa-en/:ids" component={BlogInner} />
      <Route component={Index} />
    </Switch>
  );
}
