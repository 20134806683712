import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';

import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "../src/Redux/store";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import Loader from "./components/modules/Loader/loader";
const client = new ApolloClient({
  uri: 'https://blog.godiggo.com/graphql', // Replace with your GraphQL API URL
  cache: new InMemoryCache(),
});
global.jQuery = require("jquery");
require("bootstrap");

ReactDOM.render(
  <React.StrictMode>
  <Suspense fallback={null}>
    <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={null}>
        <App />
        </Suspense>
      </PersistGate>
    </Provider>
    </ApolloProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
