import React, { Suspense } from "react";
import dotenv from "dotenv";
import './App.css';
import Routes from './routes';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getRegion } from "./utils/utils";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addingRegion } from './Redux/Actions/action';
import Loader from "./components/modules/Loader/loader";
dotenv.config();
function App() {
  const dispatch=useDispatch()
  const myCountry = useSelector((state) => state.NewHostingReducer.region);
  const[country,setCountry]=useState('')
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getRegion();
       dispatch(addingRegion(res))
        setCountry(res);
      } catch (error) {
        console.error('Error fetching region:', error);
      }
    };
    if (!myCountry) {
      fetchData();
    }
  }, [myCountry]);

 
  const PublicKey = "pk_test_51NFaj7JB3AzkAZTJf3XCIZmp4uBO6lRgKpVDkdvO8ikOtbUfJxZSdbjHSy84HUzkwIhQasRIxkB1CFqi1rsp58aZ00X6ZcCaiF";
 // const PublicKey = 'pk_test_51GvRZpA6POsBijtxzp6dVsYQV4590Kc5ez3aJphFPCJFzJ6lPCXo8BYWIbAIx5vwFKc69TL09mWMKjm4lG7pmwwP00Et22gVWm'
  const stripeTestPromise = loadStripe(PublicKey);
  return (
    <Suspense fallback={<Loader />}> 
   <div className="App">
      <Elements stripe={stripeTestPromise} >
     
   
        <Routes />
 
      </Elements>
  
    </div>
    </Suspense>
  
  );
}

export default App;
